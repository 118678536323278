<template>
    <b-modal :title="$t('keys.modificators.modificator_explanation.explain')" size="lg" v-model="modalActive">
        <div class="flex">
            <p>
                {{$t('keys.modificators.modificator_explanation.modificator_function')}}:
            </p>
            <ol>
                <li class="mb-1">
                    {{$t('keys.modificators.modificator_explanation.choose_modificator_per_business_unit')}}:
                    <ul>
                        <li>
                            {{$t('keys.modificators.modificator_explanation.choose_modificator_per_business_unit_explain')}}.
                        </li>
                    </ul>
                </li>

                <li class="mb-1">
                    {{$t('keys.modificators.modificator_explanation.name')}}:
                    <ul>
                        <li>
                            {{$t('keys.modificators.modificator_explanation.name_explain')}}.
                        </li>
                        <li>
                            {{$t('keys.modificators.modificator_explanation.name_example')}}.
                        </li>
                    </ul>
                </li>

                <li class="mb-1">
                    {{$t('keys.modificators.modificator_explanation.priority')}}:
                    <ul>
                        <li>
                            {{$t('keys.modificators.modificator_explanation.priority_explain')}}.
                        </li>
                    </ul>
                </li>

                <li class="mb-1">
                    {{$t('keys.modificators.modificator_explanation.product_discount')}}:
                    <ul>
                        <li>
                            {{$t('keys.modificators.modificator_explanation.product_discount_explain')}}.
                        </li>
                    </ul>
                </li>

                <li class="mb-1">
                    {{$t('keys.modificators.modificator_explanation.active-inactive')}}:
                    <ul>
                        <li>
                            {{$t('keys.modificators.modificator_explanation.active-inactive_explain')}}.
                        </li>
                    </ul>
                </li>

                <li class="mb-1">
                    {{$t('keys.modificators.modificator_explanation.validity')}}:
                    <ul>
                        <li>
                            {{$t('keys.modificators.modificator_explanation.validity_explain')}}.
                        </li>
                    </ul>
                </li>

                <li class="mb-1">
                    {{$t('keys.modificators.modificator_explanation.repeat')}}:
                    <ul>
                        <li>
                            {{$t('keys.modificators.modificator_explanation.repeat_explain_options')}}:
                            <ul>
                                <li>
                                    {{$t('keys.modificators.modificator_explanation.repeat_explain_1')}}.
                                </li>
                                <li>
                                    {{$t('keys.modificators.modificator_explanation.repeat_explain_2')}}.
                                </li>
                                <li>
                                    {{$t('keys.modificators.modificator_explanation.repeat_explain_3')}}.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li class="mb-2">
                    {{$t('keys.modificators.modificator_explanation.modificator_on_specific_products_categories_additions')}}:
                    <ul>
                        <li>
                            {{$t('keys.modificators.modificator_explanation.different_use_cases')}}:
                            <ul>
                                <li>
                                    {{$t('keys.modificators.modificator_explanation.specific_products')}}.
                                </li>
                                <li>
                                    {{$t('keys.modificators.modificator_explanation.specific_categories')}}.
                                </li>
                                <li>
                                    {{$t('keys.modificators.modificator_explanation.specific_additions')}}.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ol>

            <p>
                {{$t('keys.modificators.modificator_explanation.use_case_examples')}}:
            </p>
            <ul>
                <li>
                    {{$t('keys.modificators.modificator_explanation.use_case_example_1')}}.
                </li>
                <li>
                    {{$t('keys.modificators.modificator_explanation.use_case_example_2')}}.
                </li>
            </ul>
        </div>
    </b-modal>
</template>

<script>
    import {BModal} from 'bootstrap-vue'

    export default {
        components: {
            BModal
        },
        data() {
            return {
                modalActive: false
            }
        },

        methods: {
            open() {
                this.modalActive = true
            }
        }
    }
</script>

<style scoped>

</style>