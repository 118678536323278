<template>
    <b-modal :title="itemType" v-model="modalActive" no-close-on-backdrop @close="closeModal">
        <template #default>
            <TableWithCheckBox @gridReady="setFocus" ref="tableSearch" :columnDefs="columnDefs" :rowData="products" :selected="selectedProducts" :getAllItem="true" :findValueInArray="true" selectValue="id" @selectionChanged="selectionChanged"/>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BModal} from 'bootstrap-vue'
    import { required } from '@validations'
    import TableWithCheckBox from '@/views/components/Table/TableWithCheckBox'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BModal,
            TableWithCheckBox,
            BButton
        },
        props:{
            modificator: {
                type: Object,
                default: null
            },
            titleName:{
                type: String,
                default: ''
            },
            selectedArray:{
                type: Array
            },
            productSubcategories: {
                type: Array
            }
        },
        data() {
            return {
                type: '',
                itemType: null,
                tableItems: [],
                products: [],
                selectedProducts: [],
                modalActive: false,
                required

            }
        },
        computed: {
            columnDefs() {
                return [
                    { headerName: this.$t('table_fields.name'), field: 'name', editable: false, filter: true },
                    { headerName: this.$t('table_fields.subcategory'), field: 'product_category_id', filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.productSubcategories} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.productSubcategories} }, cellRenderer: (params) => this.getCategoryName(params.value)}
                ]
            }
        },
        methods: {
            getCategoryName(value) {
                if (value) {
                    const item = this.productSubcategories.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            closeModal() {
                this.products = []
            },
            removeProduct(id) {
                this.tableItems = this.tableItems.filter(el => el.id !== id)
            },
            getName() {
                if (this.type === 'Products') {
                    this.itemType = this.$t('table_fields.products')
                    this.columnDefs[0].headerName = this.itemType
                }
                if (this.type === 'Categories') {
                    this.itemType = this.$t('table_fields.category')
                    this.columnDefs[0].headerName = this.itemType
                }
                if (this.type === 'Order options categories') {
                    this.itemType = this.$t('table_fields.order_options_categories')
                    this.columnDefs[0].headerName = this.itemType
                }
            },
            selectionChanged(val) {
                this.selectedProducts = val
            },
            async loadData() {
                try {
                    this.showLoader = true
                    let url = ''

                    if (this.type === 'Products') {
                        url = '/api/client/v1/products/'
                        this.selectedProducts = JSON.parse(JSON.stringify(this.modificator.products_ids))
                    } if (this.type === 'Categories') {
                        url = '/api/client/v1/product_subcategories/'
                        this.selectedProducts = JSON.parse(JSON.stringify(this.modificator.categories_ids))
                    }
                    if (this.type === 'Order options categories') {
                        url = '/api/client/v1/product_order_options/'
                        this.selectedProducts = JSON.parse(JSON.stringify(this.modificator.order_options_categories_ids))
                    }

                    const resp = await this.$http.get(url)
                    this.products = resp.data ?? []
                    this.products = this.products.filter(product => product.disabled !== true)
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }

            },
            async open(prod_type) {
                this.type = prod_type
                this.getName()
                await this.loadData()
                this.modalActive = true
            },
            setFocus() {
                const rootElement = this.$refs.tableSearch.$el
                const inputElement = rootElement.querySelector('.ag-input-wrapper input[type="text"]')

                if (inputElement) {
                    inputElement.focus()
                }
            },
            async validate() {
                try {

                    const payload = {
                        products_ids: this.modificator.products_ids,
                        categories_ids:  this.modificator.categories_ids,
                        order_options_ids:  this.modificator.order_options_ids,
                        order_options_categories_ids:  this.modificator.order_options_categories_ids
                    }

                    if (this.type === 'Products') {
                        payload.products_ids = this.selectedProducts.map(item => item.id)
                    }
                    if (this.type === 'Categories') {
                        payload.categories_ids = this.selectedProducts.map(item => item.id)
                    }
                    if (this.type === 'Order options categories') {
                        payload.order_options_categories_ids = this.selectedProducts.map(item => item.id)
                    }

                    const resp = await this.$http.post(`/api/client/v1/modificators/items/${this.modificator.id}`, payload)
                    this.$emit('itemAdded', resp.data.id)
                    this.modalActive = false
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            }
        }
    }
</script>
<style>
@media (min-width: 500px) {
  .dropdown-menu{
    height:200px;
    overflow-y:auto;
  }
}
</style>
